import { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { getAllStartupsWithPagination } from "@src/redux/reducers/startupProfile/startupProfile";
import { getAllStartupsPublicationsWithPagination } from "@src/redux/startupsReducer";
export var useGetSortedPublicationOnStartupsPage = function (bottomObserverRef) {
    var url = useSelector(function (state) { return state.startups.leftButtonUrl; });
    var _a = useState(false), isLoadingNextPage = _a[0], setIsLoadingNextPage = _a[1];
    var checkWhatRequest = function (url, pageParam) {
        switch (url) {
            case "list/":
                return getAllStartupsWithPagination(pageParam);
            case "publications/":
                return getAllStartupsPublicationsWithPagination(pageParam);
        }
    };
    var _b = useInfiniteQuery(["fetchAllStartups", url], function (_a) {
        var _b = _a.pageParam, pageParam = _b === void 0 ? 1 : _b;
        return checkWhatRequest(url, pageParam);
    }, {
        getNextPageParam: function (lastPage, allPages) {
            // * 10 потому что страниц всего 3 и если делим на кол-во(10) то получаем дробь, потом множитель надо убрать
            var max_page = lastPage === null || lastPage === void 0 ? void 0 : lastPage.totalpages;
            var next_page = (allPages === null || allPages === void 0 ? void 0 : allPages.length) + 1;
            return next_page <= max_page ? next_page : undefined;
        },
    }), data = _b.data, isFetching = _b.isFetching, fetchNextPage = _b.fetchNextPage, status = _b.status, hasNextPage = _b.hasNextPage, refetch = _b.refetch, isFetchingNextPage = _b.isFetchingNextPage;
    var handleIntersectionObserver = function (entries) {
        var target = entries[0];
        if (target.isIntersecting && !isLoadingNextPage) {
            setIsLoadingNextPage(true);
            fetchNextPage()
                .then(function () {
                setIsLoadingNextPage(false);
            })
                .finally(function () {
                setIsLoadingNextPage(false);
            });
        }
    };
    useEffect(function () {
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0, // Trigger when bottom boundary is fully visible
        };
        var observer = new IntersectionObserver(handleIntersectionObserver, options);
        if (bottomObserverRef.current) {
            observer.observe(bottomObserverRef.current);
        }
        return function () {
            if (bottomObserverRef.current) {
                observer.unobserve(bottomObserverRef.current);
            }
        };
    }, []);
    return { data: data, isFetching: isFetching };
};
