import { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchUserViewEvents, fetchUserViewPublication, fetchUserViewVacancy } from "../../../../redux/reducers/companyByIdReducer/companyByIdReducer.";
import { useParams } from "react-router-dom";
export var useStartupProfileView = function (bottomBoundaryRef) {
    var typeFeed = useSelector(function (state) { return state.startupById.typeFeed; });
    var typeChoosedButton = useSelector(function (state) { return state.startupById.type; });
    var _a = useState(false), isLoadingNextPage = _a[0], setIsLoadingNextPage = _a[1];
    var id = useParams().id;
    var getRightThunkForRequest = function (pageParam, typeChoosedButton) {
        switch (typeChoosedButton) {
            case 1:
                return fetchUserViewPublication(pageParam, typeFeed, id);
            case 2:
                return fetchUserViewEvents(pageParam, typeFeed, id);
            case 3:
                return fetchUserViewVacancy(pageParam, id);
            default:
        }
    };
    var _b = useInfiniteQuery(["fetchUserFeedOnStartupView", typeFeed, typeChoosedButton, id], function (_a) {
        var _b = _a.pageParam, pageParam = _b === void 0 ? 1 : _b;
        return getRightThunkForRequest(pageParam, typeChoosedButton);
    }, {
        getNextPageParam: function (lastPage, allPages) {
            // * 10 потому что страниц всего 3 и если делим на кол-во(10) то получаем дробь, потом множитель надо убрать
            var max_page = lastPage === null || lastPage === void 0 ? void 0 : lastPage.totalpages;
            var next_page = (allPages === null || allPages === void 0 ? void 0 : allPages.length) + 1;
            return next_page <= max_page ? next_page : undefined;
        },
    }), data = _b.data, isFetching = _b.isFetching, fetchNextPage = _b.fetchNextPage;
    var handleIntersectionObserver = function (entries) {
        var target = entries[0];
        if (target.isIntersecting && !isLoadingNextPage) {
            setIsLoadingNextPage(true);
            fetchNextPage()
                .then(function () {
                setIsLoadingNextPage(false);
            })
                .finally(function () {
                setIsLoadingNextPage(false);
            });
        }
    };
    useEffect(function () {
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0, // Trigger when bottom boundary is fully visible
        };
        var observer = new IntersectionObserver(handleIntersectionObserver, options);
        if (bottomBoundaryRef.current) {
            observer.observe(bottomBoundaryRef.current);
        }
        return function () {
            if (bottomBoundaryRef.current) {
                observer.unobserve(bottomBoundaryRef.current);
            }
        };
    }, []);
    return { data: data, isFetching: isFetching };
};
