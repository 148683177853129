import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
export var defaultNS = "common";
i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    ns: ["common"],
    defaultNS: defaultNS,
    fallbackLng: "ru",
    debug: true,
    detection: {
        order: ["querystring", "cookie"],
        caches: ["cookie"],
        lookupCookie: "lang",
    },
});
