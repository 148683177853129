import { useEffect } from "react";
export var useOutsideClick = function (ref, onClickClose, setIsClickedOutSide) {
    useEffect(function () {
        var handleClick = function (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickClose();
                setIsClickedOutSide(true);
            }
        };
        // Bind the event listener
        document.addEventListener("mousedown", handleClick);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClick);
        };
    }, [ref, onClickClose]);
    return ref;
};
