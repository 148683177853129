import { useState, useEffect } from "react";
function useOnScreen(ref, rootMargin) {
    if (rootMargin === void 0) { rootMargin = "0px"; }
    var _a = useState(false), isIntersecting = _a[0], setIntersecting = _a[1];
    useEffect(function () {
        var observer = new IntersectionObserver(function (_a) {
            var entry = _a[0];
            setIntersecting(entry.isIntersecting);
        }, {
            rootMargin: rootMargin,
        });
        if (ref.current) {
            observer.observe(ref.current);
        }
        return function () {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);
    return isIntersecting;
}
export default useOnScreen;
