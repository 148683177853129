import { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchCompaniesSearchBlock } from "../../../../redux/searchCompanyReducer";
import { useMediaQuery } from "react-responsive";
export var useGetCompaniesSearchBlockData = function (bottomBoundaryRef) {
    var isPlanshet = useMediaQuery({
        query: "(max-width: 1240px)",
    });
    var value = useSelector(function (state) { return state.searchUsersReducer.searchCompanyValue; });
    var typeSearch = useSelector(function (state) { return state.searchUsersReducer.typeSearch; });
    var _a = useState(false), isLoadingNextPage = _a[0], setIsLoadingNextPage = _a[1];
    var _b = useInfiniteQuery(["searchCompanyExperts", value, typeSearch], function (_a) {
        var _b = _a.pageParam, pageParam = _b === void 0 ? 1 : _b;
        return fetchCompaniesSearchBlock(pageParam, value, typeSearch, isPlanshet);
    }, {
        getNextPageParam: function (lastPage, allPages) {
            var max_page = lastPage === null || lastPage === void 0 ? void 0 : lastPage.totalpages;
            var next_page = (allPages === null || allPages === void 0 ? void 0 : allPages.length) + 1;
            return next_page <= max_page ? next_page : undefined;
        },
    }), data = _b.data, isFetching = _b.isFetching, fetchNextPage = _b.fetchNextPage, status = _b.status, hasNextPage = _b.hasNextPage, refetch = _b.refetch;
    var handleIntersectionObserver = function (entries) {
        var target = entries[0];
        if (target.isIntersecting && !isLoadingNextPage) {
            setIsLoadingNextPage(true);
            fetchNextPage()
                .then(function () {
                setIsLoadingNextPage(false);
            })
                .finally(function () {
                setIsLoadingNextPage(false);
            });
        }
    };
    useEffect(function () {
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0, // Trigger when bottom boundary is fully visible
        };
        var observer = new IntersectionObserver(handleIntersectionObserver, options);
        if (bottomBoundaryRef.current) {
            observer.observe(bottomBoundaryRef.current);
        }
        return function () {
            if (bottomBoundaryRef.current) {
                observer.unobserve(bottomBoundaryRef.current);
            }
        };
    }, []);
    // const onScroll = async (event: any) => {
    //   const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;
    //   if (scrollHeight - (scrollTop + clientHeight) <= 100) {
    //     await fetchNextPage();
    //   }
    // };
    // React.useEffect(() => {
    //   document.addEventListener("scroll", onScroll);
    //   return () => {
    //     document.removeEventListener("scroll", onScroll);
    //   };
    // }, []);
    return { data: data, isFetching: isFetching, hasNextPage: hasNextPage, fetchNextPage: fetchNextPage };
};
