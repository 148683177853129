import Cookie from "js-cookie";

const SetCookie = (cookiename, usr, exp) => {
    Cookie.set(cookiename, usr, {
        expires: exp,
        secure: true,
        sameSite: 'strict',
        path: '/'
    })
}

export default SetCookie;